.col-configurator {
  &__table-wrapper {
    position: relative;
  }

  &__configurator {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__toggle-link {
    height: 43px;
    display: flex;
    align-items: center;
  }

  &__drop {
    position: absolute;
    top: 43px;
    right: 0;
    border-radius: 3px;
    background-color: var(--body-bg);
    border: 1px solid var(--table-cell-border-color);
    box-shadow: 0 0 10px 16px rgba(0, 0, 0, 0.2);

    padding: 8px 12px;
    display: none;

    &._open {
      display: block;
      z-index: 1000;
    }
  }

  &__drop-title {
    margin-bottom: 5px;
  }

  &__drop-item {
    white-space: nowrap;
    display: flex;
    align-items: center;

    label {
      padding-left: 6px;
    }
  }

  &__button-wrapper {
    margin-top: 10px;
  }
}