.multi-uploadable-admin-field {
  &__drop-zone {
    border-radius: 3px;
    background-color: #f7f7f7;
    border: 2px dashed #777;
    text-align: center;
    height: 90px;
    padding: 30px 20px 0;
  }

  &__drop-zone-title {
    color: #777;
    font-size: 15px;
  }

  &__drop-zone-progress {
    margin-top: 10px;
  }

  &__drop-zone-progress-bar {
    height: 11px;
    border-radius: 3px;
    background-color: #FC585E;
    width: 0;
  }

  &__files-list {
    display: flex;
    flex-direction: column;
    margin: 30px 0 0 0;
    width: 100%;
    padding-left: 0;
  }

  &__images-list {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(5, 1fr);
    margin: 30px 0 0 0;
    width: 100%;
    padding-left: 0;
  }

  &__item {
    position: relative;
    display: flex;
    list-style: none;
    grid-column: span 1;

    &._dragging {
      opacity: 0.5;
      border: 2px dashed black;
    }

    &._over {
      border: 2px solid #ff8585;
    }

    &_file {
      align-items: center;
      width: 100%;
      padding: 20px;
      border: 1px solid black;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    pointer-events: none;
  }

  &__remove {
    position: absolute;
    z-index: 20;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1px solid black;
    background-color: #fff;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 1px;
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
      background-color: black;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 1px;
      transform: translate3d(-50%, -50%, 0) rotate(135deg);
      background-color: black;
    }

    &_file {
      position: relative;
      top: unset;
      right: unset;
      margin-left: auto;
    }
  }

  &__form {
    label {
      display: none;
    }
  }

  &__empty-info {
    font-size: 20px;

    &._hide {
      display: none;
    }
  }

  &__unavailable {
    font-size: 20px;
  }

  &__file-icon {
    margin-right: 10px;
  }

  .form-help {
    display: none;
  }
}