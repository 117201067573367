.genplan-input-field {

}

.genplan-editor {
  position: relative;
  visibility: hidden;

  picture img {
    max-width: var(--body-max-width);
  }

  &:before {
    color: #fff;
    content: 'Загрузка...';
    position: absolute;
    display: grid;
    place-content: center;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    font-size: 12px;
    transition: 0.8s;
    pointer-events: none;
  }

  &:after {
    position: absolute;
    content: '';
    width: 100px;
    height: 100px;
    border-radius: 1000px;
    border: 3px solid #fff;
    border-bottom: none;
    border-top: none;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    z-index: 110;
    animation-duration: 1s;
    animation-name: rotate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transition: 0.8s;
    pointer-events: none;
  }

  &._initialized {
    visibility: visible;

    &:before {
      opacity: 0;
      transform: scale(0.9);
      visibility: hidden;
    }

    &:after {
      opacity: 0;
      transform: scale(3);
      visibility: hidden;
    }
  }

  &__container {
    display: inline-block;
    position: relative;
    cursor: crosshair;
    border: 2px solid var(--form-input-border-color);
    border-radius: 2px;
    background-color: #fff;
  }

  &__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    path {
      opacity: 0.5;
    }

    ellipse {
      position: relative;

      &._pulse {
        transform-origin: var(--pos-x) var(--pos-y);
        animation-duration: 1.4s;
        animation-name: pulse;
        animation-iteration-count: infinite;
      }
    }
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(0.4);
  }
  to {
    opacity: 0;
    transform: scale(3.5);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}