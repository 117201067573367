#main-menu .menu .menu-item-contents,
#main-menu .menu .menu-header .menu-header-contents {
  display: flex;
}

#main-menu .menu .menu-item {
  .menu-item-contents {
    .menu-icon {
      padding-top: 3px;
    }
  }
}

body.ea-sidebar-width-compact .main-header #header-logo {
  .custom-admin-header-link {
    &__link {
      display: block;
      position: relative;
      font-size: var(--font-size-lg);
      margin-top: -17px;
    }
  }
}

.main-header #header-logo {
  position: relative;

  .custom-admin-header-link {

    &__link {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 13px;
    }

    &__text {
      margin-left: 10px;
    }
  }
}
