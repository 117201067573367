.admin-crumbs {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 26px;
  margin-bottom: 10px;

  &__item {
    position: relative;

    &:after {
      bottom: 4px;
      right: -16px;
      position: absolute;
      content: '';
      width: 6px;
      height: 8px;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.93472 2.71449C5.46736 3.11542 5.46596 3.91542 4.93193 4.31449L1.5986 6.80541C0.939146 7.2982 6.11519e-07 6.8276 6.47504e-07 6.00436L3.89224e-07 1.00436C4.25279e-07 0.179515 0.942378 -0.290648 1.60139 0.20541L4.93472 2.71449Z' fill='%23686868'/%3E%3C/svg%3E");
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__item-entity-type {
    padding-bottom: 2px;
    display: block;
    min-height: 18px;
    font-weight: 500;
    font-size: 13px;
    color: var(--true-gray-500);
  }

  &__item-entity-verbose {
    font-size: 16px;
    display: block;
  }
}